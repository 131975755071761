.SentenceWord {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 11px;
  font-size: 0.875rem;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  margin: 0.2em;
  outline: none;
  position: relative;
}

.selected {
  background-color: rgba(192, 6, 6, 0.12);
}

.new {
  color: blue;
}
