@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.SentenceTextArea {
  font-family: "Comic Sans MS", "Sassoon Primary", "Gill Sans Infant",
    sans-serif;
  font-size: 4em;
  min-height: 6em;
  padding: 12px;
  outline: none;
  margin: 10px;
  border: 0px;
  /* If the width and height are 100% then the borders of the surrounding page are not drawn. */
  width: 98%;
  height: 98%;
  box-sizing: border-box;
}

.SentenceWord {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 11px;
  font-size: 0.875rem;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  margin: 0.2em;
  outline: none;
  position: relative;
}

.selected {
  background-color: rgba(192, 6, 6, 0.12);
}

.new {
  color: blue;
}

.SentenceWords {
  min-height: 15em;
  min-width: 15em;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

